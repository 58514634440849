import React from "react";
import "./reportsStyles.css";
import moment from "moment";
import {apiRequest} from "../components/Globals";
import {notify} from "../components/Notify";
import {SHOWROOMS_AVAILABLE} from "../config/Globals";
import Chart from "react-apexcharts";

export default class ShowroomReports extends React.Component {
    numberFormat(num, simpleFix) {
        if (!num) {
            return 0;
        }

        if (simpleFix) {
            return (parseFloat(num)).toFixed(2);
        }

        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    customTooltip(e, series, seriesIndex, dataPointIndex, w) {
        const currentYear = e[0]?.tooltipData[dataPointIndex];
        const lastYear = e[1] ? e[1]?.tooltipData[dataPointIndex] : {};
        const isCompare = e.length > 1;

        let currentYearDate;
        let lastYearDate;

        if (this.reportType === 'weekly') {
            let lastDate;
            if (isCompare) {
                lastDate = e[1].dates[dataPointIndex]?.split('-');

                if (lastDate && lastDate[0]) {
                    lastDate[0] = lastDate[0] + ' ' + e[1].name;
                }
                if (lastDate && lastDate[1]) {
                    lastDate[1] = moment(lastDate[1]).set('year', e[1].name).format('DD MMM YYYY');
                    lastDate = lastDate.join(' - ');
                    lastYearDate = lastDate;
                }
            }

            if (e.length > 2) {
                e.some(seriesData => {
                    if (seriesData.name === this.yearButtons[0].label) {
                        currentYearDate = seriesData.dates[dataPointIndex];
                        return true;
                    }
                    return false;
                })
            } else {
                currentYearDate = `${e[0].dates[dataPointIndex]}`;
            }


        } else if (this.reportType === 'monthly') {
            currentYearDate = `${e[0].dates[dataPointIndex]}`;
            if (isCompare) {
                lastYearDate = `${e[1].dates[dataPointIndex]}`;
            }

        } else if (this.reportType === 'yearly') {
            currentYearDate = moment(e[0].dates[dataPointIndex]).format('MMMM YYYY');

            if (isCompare) {
                lastYearDate = moment(e[1].dates[dataPointIndex]).set('year', e[1].name).format('MMMM YYYY');
            }
        } else {
            currentYearDate = moment(e[0].dates[dataPointIndex]).format('DD MMM YYYY');

            if (isCompare) {
                lastYearDate = moment(e[1].dates[dataPointIndex]).set('year', e[1].name).format('DD MMM YYYY');
            }
        }



        if (e.length > 1) {
            return `
                <div style="padding: 10px; display: flex; gap: 15px">
                    <div>
                      <div style="display: flex; gap: 10px; margin-bottom: 10px; align-items: center">
                          <div style="width: 15px; height: 15px; background-color: ${w.globals.colors[0]}; border-radius: 50%"></div> 
                          <b>${currentYearDate}</b>
                      </div>
                       <ul>
                            <li>Total Leads: ${this.numberFormat(currentYear.total)}</li>
                            <li>Self Booking: ${this.numberFormat(currentYear.book)} (${currentYear.percent}%)</li>
                            <li>Showroom Leads: ${this.numberFormat(currentYear.showroom)}</li>
                        </ul>
                    </div>
                       
                    ${lastYear ?
                    `<div>
                        <div style="display: flex; gap: 10px; margin-bottom: 10px; align-items: center">
                            <div style="width: 15px; height: 15px; background-color: ${w.globals.colors[1]}; border-radius: 50%"></div>
                            <b>${lastYearDate}</b>
                        </div>
                        <ul style="margin-bottom: 0">
                            <li>Total Leads: ${this.numberFormat(lastYear.total)}</li>
                            <li>Self Booking: ${this.numberFormat(lastYear.book)} ${lastYear.percent}%)</li>
                            <li>Showroom Leads: ${this.numberFormat(lastYear.showroom)}</li>
                        </ul>
                     </div>` 
                    : ""}
                </div>
            `
        }

        return `
            <div style="padding: 10px">
             <div style="display: flex; gap: 10px; margin-bottom: 10px; align-items: center">
                  <div style="width: 15px; height: 15px; background-color: ${w.globals.colors[0]}; border-radius: 50%"></div> 
                  <b>${currentYearDate}</b>
              </div>
              
              <ul style="margin-bottom: 0">
                <li>Total Leads: ${this.numberFormat(currentYear.total)}</li>
                <li>Self Booking: ${this.numberFormat(currentYear.book)} (${currentYear.percent}%)</li>
                <li>Showroom Leads: ${this.numberFormat(currentYear.showroom)}</li>
                </ul>
            </div>
       `
    }

    state = {
        data: []
    };

    reportList: {} = {
        data: [],
        btnSelfBooked: 0,
        btnShowroomBooked: 0,
    };
    reportType: string = 'daily';
    yearButtons: [] = [];

    stats: {} = {
        current: {
            selfBooked: 0,
            showroomBook: 0
        },
        previous: {
            selfBooked: 0,
            showroomBook: 0
        }
    }
    isLoading = false;

    countPercents = 0;
    bookCount = 0;
    bookPercents = 0;
    averageTotalPercent = 0;


    constructor(props) {
        super(props);
        let newState = Object.create(null, Object.getOwnPropertyDescriptors(props.reportState));

        newState.options.tooltip.custom = ({
            series,
            seriesIndex,
            dataPointIndex,
            w
        }) => this.customTooltip(this.state.series, series, seriesIndex, dataPointIndex, w);

        this.state = newState;
        this.reportType = props.reportType;
        this.slots = props.slots;
        this.reportList = props.reportList;

        this.customTooltip = this.customTooltip.bind(this);
        this.calcTotalPercents = this.calcTotalPercents.bind(this);
        this.numberFormat = this.numberFormat.bind(this);
        this.calcPercents = this.calcPercents.bind(this);

        this.assignYearBtns();
        this.assignMainReportData();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if ((prevProps.reportType !== this.reportType || this.props.reportState !== prevProps.reportState)
            && this.props.reportState.endDate && !this.isLoading) {

            this.isLoading = true;

            if (this.yearButtons !== this.props.yearButtons && this.props.clickedBtn) {
                return this.chooseCharYear(this.props.clickedBtn.year, this.props.clickedBtn.firstBtn);
            }
            this.assignYearBtns();


            this.assignMainReportData();
        }
    }

    assignYearBtns() {
        let btns = [];

        if (this.yearButtons.length === 0 || !this.props.clickedBtn) {
            this.props.yearButtons.forEach(btn => {
                let newBtn = Object.create({}, Object.getOwnPropertyDescriptors(btn));
                btns.push(newBtn);
            })
            this.yearButtons = btns;

        } else {
            this.yearButtons.forEach(btn => {
                this.props.yearButtons.some(propsBtn => {
                    if (propsBtn.label === btn.label) {

                        btn.pressed = propsBtn.pressed;
                        return true;
                    }
                    return false;
                })
            })
        }
    }

    assignMainReportData() {
        this.stats = this.props.stats;
        this.reportType = this.props.reportType;
        this.reportList = this.props.reportList;

        let data = [];
        this.props.reportState.data.forEach(item => {
            let newObj = Object.create({}, Object.getOwnPropertyDescriptors(item));
            newObj.isShowRoom = true;
            data.push(newObj);
        });

        this.reportList = Object.create({}, Object.getOwnPropertyDescriptors(this.reportList));
        this.reportList.data = data;


        this.buildReports();
    }

    chooseCharYear(year, firstBtn) {
        if (year.btnPressed === true) {
            return this.getReports(!firstBtn, year);
        }
        let reportList = [];

        this.yearButtons.forEach(btn => {
            if(btn.pressed) {
                reportList = reportList.concat(btn.reports);
            }
        })

        this.calcYearBtn();
        this.reportList.data = reportList;
        this.isLoading = false;

        this.setState({data: reportList});
    }

    buildReports() {
        let btnPressed = [];
        this.reportList.data = [];
        this.countPercents = 0;
        this.bookCount = 0;
        this.bookPercents = 0;

        this.yearButtons.forEach(btn => {
            if (btn.pressed === true) {
                return btnPressed.push({
                    start: btn.yearFrom,
                    end: btn.yearTo,
                    btnPressed: true,
                    btnYear: btn.label
                });
            }
        })

        this.reportList.totalBooked = 0;
        this.reportList.totalShowroom = 0;

        // [1, 2].forEach(request => {
        btnPressed.forEach((year, key) => {
            this.getReports(key > 0, year);
        });
    }

    calcTotalPercents(report) {
        this.countPercents = 0;
        this.bookCount = 0;

        if (report) {
            this.countPercents += parseInt(report.self_percent) * parseInt(report.self_booked);
            this.bookCount += parseInt(report.self_booked);

            return;
        }

        let countPercents = 0;
        let bookCount = 0;

        this.reportList.data.forEach(report => {
            countPercents += parseFloat(report.customHTML.percent) * parseInt(report.customHTML.book);
            bookCount += parseInt(report.customHTML.book);
        })

        if (countPercents > 0) {
            this.averageTotalPercent = countPercents / bookCount;
        }
    }

    calcPercents(aNum, bNum) {
        let res = 0;
        let decrease = aNum - bNum;
        let increase = bNum - aNum;

        let resDecrease = decrease / aNum * 100;
        let resIncrease = increase / bNum * 100;

        if (resDecrease > resIncrease) {
            res = resDecrease;
        } else {
            res = resIncrease;
        }

        return res;
    }

    calcStats(stats) {
        if (stats) {
            this.stats = {
                current: {
                    total: 0,
                    totalPercent: 0,
                    bookedPercent: 0,
                    showroomPercent: 0,
                    period: stats.current.period,
                    selfBooked: stats.current.self_booked,
                    showroomBook: stats.current.showroom_booked
                },
                previous: {
                    total: 0,
                    totalPercent: 0,
                    bookedPercent: 0,
                    showroomPercent: 0,
                    period: stats.previous.period,
                    selfBooked: stats.previous.self_booked,
                    showroomBook: stats.previous.showroom_booked
                }
            }

            //total bookings + showroom percent
            let currTotal = this.stats.current.selfBooked + this.stats.current.showroomBook;
            let prevTotal = this.stats.previous.selfBooked + this.stats.previous.showroomBook;
            if (this.stats.current.selfBooked > 0 || this.stats.current.showroomBook > 0
                || this.stats.previous.selfBooked > 0 || this.stats.previous.showroomBook > 0) {

                this.stats.current.total = currTotal;
                this.stats.previous.total = prevTotal;
                this.stats.current.totalPercent = this.calcPercents(currTotal, prevTotal);
            }

            //total bookings percent
            if (this.stats.current.selfBooked > 0 || this.stats.previous.selfBooked > 0) {
                this.stats.current.bookedPercent = this.calcPercents(this.stats.current.selfBooked, this.stats.previous.selfBooked);
            }

            //total showroom percent
            if (this.stats.current.showroomBook > 0 || this.stats.previous.showroomBook > 0) {
                this.stats.current.showroomPercent = this.calcPercents(this.stats.current.showroomBook, this.stats.previous.showroomBook);
            }
        }
    }


    calcYearBtn() {
        this.reportList.btnSelfBooked = 0;
        this.reportList.btnShowroomBooked = 0;

        this.yearButtons.forEach((btn, key) => {
            if (btn.pressed === true && key === 0) {
                this.reportList.btnSelfBooked += btn.totalBooked;
                this.reportList.btnShowroomBooked += btn.totalShowroom;
            }
        })
    }

    getReports(lastRequest, year) {
        let payload = {
            "type": this.reportType,
            "dateFrom": moment(this.props.reportState.startDate).format('YYYY-MM-DD'),
            "dateTo": moment(this.props.reportState.endDate).format('YYYY-MM-DD')
        };

        payload.showRoomID = SHOWROOMS_AVAILABLE.cannington;

        if (lastRequest) {
            payload.dateFrom = year.start;
            payload.dateTo = year.end;
        }


        apiRequest('/report/showroom', payload)
            .then(result => {
                if (!result || !result.custom) {
                    this.isLoading = false;
                    return;
                }

                let mainYear;

                this.yearButtons.some((btn, key) => {
                    if (key === 0) {
                        mainYear = btn.label;
                        return true;
                    }
                    return false;
                });

                result.custom.dates.forEach((report, reportKey) => {
                    let label = report.label;

                    if (lastRequest) {
                        switch (this.reportType) {
                            case 'daily':
                                let parts = label.split('-');

                                if ((mainYear - parts[0]) > 1) {
                                    parts[0] = mainYear;
                                } else {
                                    parts[0] = parseInt(parts[0]) + 1;
                                }

                                label = parts.join('-');

                                break;

                            case 'weekly':
                                let wParts = label.split('-');
                                let wp1 = moment(wParts[0]).format('YYYY');
                                let wp2 = moment(wParts[1]).format('YYYY');

                                wParts[0] = moment(wParts[0]).set('year', parseInt(wp1) + 1);
                                wParts[1] = moment(wParts[1]).set('year', parseInt(wp2) + 1);
                                label = wParts[0].format('DD MMM YYYY') + ' - ' + wParts[1].format('DD MMM YYYY');

                                break;

                            case 'monthly':
                                let mParts = label.split(',');
                                mParts[0] = moment(mParts[0]).set('year', parseInt(mParts[0]) + 1);
                                label = mParts[0].format('YYYY') + ', ' + mParts[1].trim();

                                break;

                            default:
                                if ((mainYear - label) > 1) {
                                    label = mainYear;
                                } else {
                                    label = (parseInt(label) + 1).toString();
                                }

                                break;

                        }
                    }

                    let bookings = parseInt(report.self_booked);
                    let totalShowBook = bookings + parseInt(report.showroom_booked);
                    let weeklyDateKey;
                    let weeklyShowDate;

                    if (this.reportType === 'weekly') {
                        let splitLabel = label.split('-');
                        weeklyDateKey = `${moment(splitLabel[0])?.format('MMM DD')}`;
                        weeklyShowDate = `${moment(label.split('-')[0])?.format('DD MMM YYYY')} - ${moment(splitLabel[1])?.format('DD MMM YYYY')}`;
                    }

                    this.reportList.data.push({
                        customHTML: {
                            total: totalShowBook,
                            book: bookings,
                            showroom: parseInt(report.showroom_booked),
                            percent: (report.self_percent).toFixed(2),
                        },
                        group: lastRequest ? year.btnYear : mainYear,
                        id: 'tmpId' + Math.random(),
                        key: this.reportType === 'weekly' ? weeklyDateKey : label,
                        weekNum: report.week_num ? parseInt(report.week_num) : null,
                        date: weeklyShowDate,
                        isShowRoom: true,
                        value: (parseFloat(report.self_booked) + parseFloat(report.showroom_booked)).toFixed(2),
                    });

                    if (lastRequest) {
                        this.yearButtons.some(btn => {
                            if (btn.label === year.btnYear && reportKey === 0) {
                                btn.reports = [];
                            }

                            if (btn.label === year.btnYear) {
                                btn.totalBooked = result.custom.overall.self_booked;
                                btn.totalShowroom = result.custom.overall.showroom_booked;
                                btn.reports.push(this.reportList.data[this.reportList.data.length - 1]);
                                return true;
                            }
                            return false;
                        })
                        // this.calcYearBtn();

                    } else {
                        this.calcStats(result.custom.stats);

                        this.yearButtons.some((btn, key) => {
                            if (key === 0 && reportKey === 0) {
                                btn.reports = [];
                            }

                            if (key === 0) {
                                btn.pressed = true;
                                btn.totalBooked = result.custom.overall.self_booked;
                                btn.totalShowroom = result.custom.overall.showroom_booked;
                                btn.reports.push(this.reportList.data[this.reportList.data.length - 1]);
                                return true;
                            }
                            return false;
                        })
                        this.calcYearBtn();
                    }

                });

                switch (this.reportType) {
                    case 'weekly':
                        this.reportList.data.sort(function (a, b) {
                            let aDate = a.weekNum;
                            let bDate = b.weekNum;

                            if (aDate < bDate) {
                                return -1;
                            }
                            if (aDate > bDate) {
                                return 1;
                            }

                            return 0;

                        });

                        this.reportList.data.forEach(rep => {
                            this.reportList.data.some(newRep => {
                                if (newRep.weekNum === rep.weekNum) {
                                    rep.key = newRep.key;
                                    return true;
                                }
                                return false;
                            })
                        })

                        break;
                    case 'yearly':
                        this.reportList.data.sort(function (a, b) {
                            let newDate = a.key;
                            let secDate = b.key;

                            return moment.utc(newDate).diff(moment.utc(secDate));
                        });

                        break;


                    default:
                        this.reportList.data.sort(function compareFn(a, b) {
                            let aDate = a.key.split('-');
                            aDate = aDate[0] * 365 + aDate[1] * 12 + aDate[2];

                            let bDate = b.key.split('-');
                            bDate = bDate[0] * 365 + bDate[1] * 12 + bDate[2];

                            if (aDate < bDate) {
                                return -1;
                            }
                            if (aDate > bDate) {
                                return 1;
                            }

                            return 0;
                        });
                }

                this.calcTotalPercents();

                this.isLoading = false;

                this.setState({
                    data: this.reportList.data,
                    series: this.getSeries(),
                    options: {
                        ...this.getXAxis(lastRequest),
                        color: {
                            scale: {
                                [this.yearButtons[0]?.label]: "#000",
                                [this.yearButtons[1]?.label]: "#c1bfbf",
                                [this.yearButtons[2]?.label]: "#c1bfbf",
                                [this.yearButtons[3]?.label]: "#c1bfbf",
                            }
                        },
                    }
                })
            }).catch(error => {
            notify({text: error.message ? error.message : 'No data'});
        });
    }

    getSeries() {
        const series = [];

        this.yearButtons.forEach(btn => {
            if (btn.pressed) {
                series.push({
                    name: btn.label,
                    type: 'area',
                    tooltipData: [],
                    data: [],
                    dates: [],
                    isShowRoom: true
                })
            }
        })

        this.reportList.data.forEach(item => {
            series.some(series => {
                if (series.name === item.group) {
                    if (this.reportType === 'daily' || this.reportType === 'monthly' || this.reportType === 'yearly') {
                        series.dates.push(item.key);
                    }
                    if (this.reportType === 'weekly') {
                        series.dates.push(item.date);
                    }
                    series.isShowRoom = item.isShowRoom;
                    series.tooltipData.push(item.customHTML);
                    series.data.push(item.value);
                    return true;
                }
                return false;
            });
        })
        return series;
    }

    getReportTypeName() {
        let res = 'Day';

        if (this.reportType === 'weekly') {
            res = 'Week';
        } else if (this.reportType === 'monthly') {
            res = 'Month';
        } else if (this.reportType === 'yearly') {
            res = 'Year';
        }

        return res;
    }

    getXAxis(lastRequest) {
        let options = {
            ...this.state.options
        }

        options.xaxis = {
            categories: [],
            title: {
                text: this.getReportTypeName()
            }
        }
        options.yaxis = {
            title: {
                text: 'Bookings'
            },
            min: 0,
            max: 0
        };

        this.reportList.data.forEach(item => {
            if (parseInt(item.value) > options.yaxis.max) {
                options.yaxis.max = parseInt(item.value);
            }
        })

        if (lastRequest) {
            return options;
        }

        options.xaxis.categories = this.reportList.data.map(item => {
            if (parseInt(item.value) > options.yaxis.max) {
                options.yaxis.max = parseInt(item.value);
            }

            if (this.reportType === 'daily' || this.reportType === 'monthly' || this.reportType === 'yearly') {
                return item.key;
            }
            if (this.reportType === 'weekly') {
                return item.date;
            }
        })

        return options;
    }

    render() {
        // console.log(this.state.data)
        // console.log('bbbbSHOWRO', this.yearButtons)
        // console.log('RPRPRPR', this.reportList)
        // console.log(this.state.options)
        // console.log(this.stats)

        return (
            <>
                <div className="panel">
                    <div className="panel__top">
                        <div className="panel__title">
                            Showroom Cannington Leads
                        </div>
                    </div>

                    <div className="panel__body">
                        <div className="report__summary">

                            <div className="item">
                                <div className="report__summary__label">Leads</div>
                                <div
                                    className="report__summary__value">{this.numberFormat(this.reportList.btnShowroomBooked + this.reportList.btnSelfBooked)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.stats.previous.total > this.stats.current.total ? 'down' : 'up'}`}> {this.numberFormat(this.stats.current.totalPercent, true)}% {this.stats.previous.total < this.stats.current.total || (this.reportList.btnShowroomBooked + this.reportList.btnSelfBooked) === 0 ? String.fromCharCode(9650) : String.fromCharCode(9660)}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Self Booking</div>
                                <div
                                    className="report__summary__value">{this.reportList.btnSelfBooked > 0 ? `${this.numberFormat(this.reportList.btnSelfBooked)} (${((this.reportList.btnSelfBooked) * 100 / (this.reportList.btnSelfBooked + this.reportList.btnShowroomBooked)).toFixed(2)}%)` : 0}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.stats.previous.selfBooked > this.stats.current.selfBooked ? 'down' : 'up'}`}> {this.numberFormat(this.stats.current.bookedPercent, true)}% {this.stats.previous.selfBooked < this.stats.current.selfBooked || this.reportList.btnSelfBooked === 0 ? String.fromCharCode(9650) : String.fromCharCode(9660)}</span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="report__summary__label">Showroom Leads</div>
                                <div
                                    className="report__summary__value"> {this.numberFormat(this.reportList.btnShowroomBooked)}
                                    <span
                                        className={`report__summary__percent report__summary__percent--${this.stats.previous.showroomBook > this.stats.current.showroomBook ? 'down' : 'up'}`}> {this.numberFormat(this.stats.current.showroomPercent, true)}% {this.stats.previous.showroomBook < this.stats.current.showroomBook || this.reportList.btnShowroomBooked === 0 ? String.fromCharCode(9650) : String.fromCharCode(9660)} </span>
                                </div>
                            </div>
                        </div>

                        <div className={'ih'}>
                            <Chart
                                key={Math.random()}
                                height={'600px'}
                                options={this.state.options}
                                series={this.state.series}
                                type="area"
                            />
                        </div>

                    </div>

                </div>
            </>
        );
    }
}